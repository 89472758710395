import { Dialog } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import FaClose from "@/components/SVG/Fontawesome/FaClose";
import { resetVideoModalIntent, selectVideoModal } from "@/store/slices/video";
import VideoPlayer from "../VideoPlayer";
import Portal from "@/layouts/Portal";


const VideoModal = (props: { page: string; }) => {
  const dispatch = useDispatch();
  const videoData = useSelector(selectVideoModal);

  const closeModal = () => {
    dispatch(resetVideoModalIntent());
  }

  const handleCloseClick = () => {
    closeModal();
  }

  return (
    <>
      <Dialog as="div" open={videoData.showModal} className="absolute z-[9999999]" onClose={closeModal}>
        <div className="fixed inset-0 bg-black/60" aria-hidden="true"/>
        <Portal>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Panel className="absolute inset-0 flex flex-col justify-center w-full transform overflow-hidden rounded-2xl text-body text-left align-middle transition-all ">
                <div className="min-w-80 w-screen-4/5 lg:!min-w-screen-4/5 mx-auto flex flex-shrink-0 items-center  bg-body-bg pr-2 justify-end rounded-t-md  p-1 dark:border-opacity-50 rounded-none">
                  <button
                    onClick={handleCloseClick}
                    type="button"
                    className="btn-tertiary pr-0 py-0">
                      Close <FaClose className="ml-1" />
                  </button>
                </div>
                <div className="relative max-w-screen max-h-screen object-contain mx-auto">
                  <VideoPlayer key={`${videoData.question_id}`}
                    className="min-w-80 !w-screen-4/5 lg:!min-w-screen-4/5 rounded-md"
                    videoData={videoData}
                    query={{}}
                    page={props.page}
                    avoidAds
                    skipOverlays
                    fetchResource
                    isInModal
                  />
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Portal>
      </Dialog>
    </>
  )
}

export default VideoModal;